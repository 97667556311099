import React from 'react'
// import Img from 'gatsby-image'

// import styles from './hero.module.css'
import "../styles/hero.scss"

export default ({ data }) => (
    <div>
      <div className="picture">
        <div className="square">
          <p>フルーツとか伝統野菜とかナスとか...</p>
        </div>
      </div>
    </div>
)
